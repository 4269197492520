<template>
    <div class="BuyPage">
        <div class="head">
            <div class="picture">
                <img :src="item.USER_PIC ? require(`@/assets/profiles/${item.USER_PIC}`) : require('@/assets/img/default.png')" />
            </div>

            <p class="title">{{ item.NICK_NAME }}</p>

            <div class="sum">
                <p class="sell">出售总数</p>
                <p class="sell-num">$ {{ item.AMT }}</p>
                <p class="surplus">剩余总数</p>
                <p class="surplus-num">$ {{ item.AMT_SELL }}</p>
            </div>
        </div>

        <div class="details">
            <p class="item">选择付款方式</p>
            <van-cell :title="title" is-link arrow-direction="down" @click="onSelect" />
            <van-action-sheet v-model:show="show" title="选择收款方式">
                <div class="content">
                    <van-radio-group v-model="paytype">
                        <van-cell-group>
                            <van-cell clickable @click="getPaytype('1')">
                                <template #title>
                                    <img :src="require('@/assets/img/pay_icon_1.png')" />
                                    <span>银行卡</span>
                                </template>
                                <template #right-icon>
                                    <van-radio name="1" :disabled="isPay_bank" />
                                </template>
                            </van-cell>

                            <van-cell clickable @click="getPaytype('3')">
                                <template #title>
                                    <img :src="require('@/assets/img/pay_icon_3.png')" />
                                    <span>支付宝</span>
                                </template>
                                <template #right-icon>
                                    <van-radio name="3" :disabled="isPay_alipay" />
                                </template>
                            </van-cell>

                            <van-cell clickable @click="getPaytype('2')">
                                <template #title>
                                    <img :src="require('@/assets/img/pay_icon_2.png')" />
                                    <span>微信</span>
                                </template>
                                <template #right-icon>
                                    <van-radio name="2" :disabled="isPay_wechat" />
                                </template>
                            </van-cell>
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </van-action-sheet>

            <p class="item" v-if="isSplit === 1">购买金额</p>
            <van-field v-if="isSplit === 1" v-model="amt" type="number" placeholder="0.00" />

            <p class="item">备注：（选填）</p>
            <van-field v-model="remark" placeholder="请输入备注" />
        </div>

        <div class="split">
            <p class="left">是否拆分</p>
            <div class="right">
                <span @click="split" :class="{ isSplit: isSplit === 1 }">拆分</span>
                <span @click="isSplit = 0" :class="{ isSplit: isSplit === 0 }">不拆分</span>
            </div>
        </div>

        <van-button class="btn" type="primary" block round @click="buy">购买</van-button>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getBankList, IWannaBuy } from '@/api'
import { Dialog, Toast } from 'vant'

export default {
    setup() {
        const state = reactive({
            title: '付款方式',
            show: false,
            isNoCard: false,
            bankArr: [],

            item: {},
            isPay_bank: true,
            isPay_wechat: true,
            isPay_alipay: true,
            paytype: '', // 收款类型
            amt: '', // 购买数量
            remark: '', // 备注
            isSplit: 0 // 是否拆分 0否1是
        })

        const router = useRouter()
        const route = useRoute()

        state.item = route.query
        state.bankArr = state.item.PAY_BANK.split('')

        console.log(123, state.item)

        onMounted(() => {
            // 1银行卡 2微信 3支付宝
            getBankList().then(res => {
                let data = res.data

                if (!data.length) {
                    state.isNoCard = true
                    return
                }

                data.forEach(item => {
                    if (item.BANK_TYPE === '1' && state.bankArr.includes('1')) {
                        state.isPay_bank = false
                    }
                    if (item.BANK_TYPE === '2' && state.bankArr.includes('2')) {
                        state.isPay_wechat = false
                    }
                    if (item.BANK_TYPE === '3' && state.bankArr.includes('3')) {
                        state.isPay_alipay = false
                    }
                })

                if (state.isPay_bank && state.isPay_wechat && state.isPay_alipay) {
                    state.isNoCard = true
                    return
                }

                if (!state.isPay_wechat) {
                    state.title = '微信'
                    state.paytype = '2'
                }
                if (!state.isPay_alipay) {
                    state.title = '支付宝'
                    state.paytype = '3'
                }
                if (!state.isPay_bank) {
                    state.title = '银行卡'
                    state.paytype = '1'
                }
            })
        })

        const onSelect = () => {
            if (state.isNoCard) {
                Dialog.confirm({
                    message: '是否添加收款方式？'
                })
                    .then(() => {
                        router.push('/BankInformation')
                    })
                    .catch(() => {
                        console.log('弹窗点击取消了')
                    })
            } else {
                state.show = true
            }
        }

        const getPaytype = paytype => {
            if (paytype === '1' && !state.isPay_bank) {
                state.title = '银行卡'
                state.paytype = paytype
                state.show = false
            }
            if (paytype === '2' && !state.isPay_wechat) {
                state.title = '微信'
                state.paytype = paytype
                state.show = false
            }
            if (paytype === '3' && !state.isPay_alipay) {
                state.title = '支付宝'
                state.paytype = paytype
                state.show = false
            }
        }

        const buy = () => {
            Dialog.confirm({
                messageAlign: 'left',
                title: '温馨提示',
                message: '亲爱的【买家】你好，请用你选择的付款方式进行转账操作，并且转账的【账户号码】和【姓名】要跟您选择的支付方式信息完全一致，否则卖家有权不打币给您，造成的损失由您个人承担。'
            })
                .then(() => {
                    IWannaBuy({
                        paytype: state.paytype,
                        amt: state.isSplit === 0 ? state.item.AMT : state.amt,
                        remark: state.remark,
                        isSplit: state.isSplit,
                        orderID: state.item.ID
                    }).then(res => {
                        if (res.status) {
                            let subID = res.data.SUBID
                            Toast.success('操作成功！')
                            router.push({ path: '/MyOrderPage', query: { subID } })
                        } else {
                            Toast.fail(res.message)
                        }
                    })
                })
                .catch(() => {})
        }

        const split = () => {
            if (state.item.IS_SPLIT !== '0') state.isSplit = 1
        }

        return {
            ...toRefs(state),
            router,
            getPaytype,
            onSelect,
            buy,
            split
        }
    }
}
</script>

<style lang="scss" scoped>
.BuyPage {
    background: $background;
    overflow: auto;

    .head {
        width: 100%;
        height: h(214);
        text-align: center;
        line-height: h(150);
        font-size: 30px;
        color: #ffffff;
        background: #3366ee;
        background-image: url('~@/assets/img/help-bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        border-bottom-left-radius: 20%;
        border-bottom-right-radius: 20%;

        .picture {
            position: absolute;
            top: calc(h(25) + 46px);
            left: w(13);
            height: h(52);
            border-radius: 50%;
            overflow: hidden;

            img {
                width: h(52);
                height: h(52);
            }
        }

        .title {
            position: absolute;
            top: calc(h(35) + 46px);
            left: w(70);
            line-height: h(20);
            font-size: 14px;
            color: #ffffff;
        }

        .sum {
            font-size: 12px;
            color: #ffffff;

            p {
                height: h(20);
                line-height: h(20);
            }

            .sell {
                position: absolute;
                top: calc(h(90) + 46px);
                left: w(20);
            }
            .sell-num {
                position: absolute;
                top: calc(h(120) + 46px);
                left: w(20);
                font-weight: bold;
                font-size: 18px;
            }
            .surplus {
                position: absolute;
                top: calc(h(90) + 46px);
                left: w(190);
            }
            .surplus-num {
                position: absolute;
                top: calc(h(120) + 46px);
                left: w(190);
                font-weight: bold;
                font-size: 18px;
            }
        }
    }

    .details {
        margin: h(-50) auto 0;
        width: w(346);
        background: #ffffff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: h(15) w(11);

        .content {
            padding: 16px 16px h(30);
            text-align: left;

            img {
                width: h(45);
                height: h(45);
                margin-right: w(10);
                vertical-align: middle;
            }

            span {
                font-size: 14px;
                line-height: 109.48%;
            }
        }

        .item {
            margin: h(15) 0 h(10);
            font-size: 14px;
            line-height: 18px;
            color: #333333;
        }
    }

    .split {
        width: w(346);
        height: h(60);
        box-sizing: border-box;
        line-height: h(60);
        padding: 0 w(15);
        margin: h(12) auto 0;
        background: #ffffff;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;

        .right {
            span {
                background: rgba(109, 136, 251, 0.1);
                color: #333333;
                border-radius: 37px;
                padding: 10px 12px;
                margin-left: w(12);
            }

            span.isSplit {
                background: #3366ee;
                color: #ffffff;
            }
        }
    }

    .btn {
        width: w(346);
        height: h(40);
        margin: h(42) auto 0;
    }
}
</style>
